<template>
  <Header/>
  <main>
    <aside id="bio">
      <h2>A propos</h2>
      <p>Les sites de la famille ont été réalisés à l'aide du framework Vue.js et d'API en PHP. </p>
      <p>Cette page sert de routeur vers les autres sites, c'est pourquoi elle est peu développée. </p>
      <p>Sites à venir : <br>
        Base de Données de recettes<br>
        Blog pour chaque utilisateur<br>
        Musique ?
      </p>
      <p>Merci de rapporter les éventuels bugs découvert à l'administrateur. </p>
    </aside>
    <div id="content">
      <div id="welcome">
        <h2>Bienvenue à la maison</h2>
        <p>Toute la famille réunie sur son site personnalisé.</p>
        <ul>
          <li><a href="https://streaming.peytersfamily.ch">On regarde un <b>film</b> ?</a></li>
        </ul>
      </div>
      <div id="image">
        <img alt="Image d'une famille composée d'une femme, trois hommes et deux enfants, assis sur un canapé"
             src="./assets/img/famille.jpg">
      </div>
    </div>
  </main>
  <footer>
    <div>
      <p>
        Depuis 2021, le site se développe lentement autour d'un projet de téléinformatique.
        Toute l'énergie investie commence à porter ses fruits.
      </p>
    </div>
  </footer>
</template>

<script>
import Header from "@/components/Header.vue";

export default {
  name: 'App',
  components: {
    Header,
  }
}
</script>

<style></style>
