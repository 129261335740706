<template>
  <li class="dropdown"><a href="#">{{ link }}</a>
    <ul>
      <li v-for="(dest, id) in dests" :key="id">
        <template v-if="dest['func']"><a @click.prevent="dest['func']" href="">{{ dest['label'] }}</a></template>
        <template v-else><a :href="dest['url']">{{ dest['label'] }}</a></template>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  name: "Dropdown",
  props: ['link', 'dests'],
}
</script>
