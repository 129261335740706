<template>
  <header>
    <div>
      <h1>Peytersfamily</h1>
      <nav id="home" class="main-menu">
        <ul class="menu-list">
          <Dropdown link="Pages" :dests="dests"/>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
import Dropdown from "@/components/Dropdown.vue";

export default {
  name: "Header",
  data() {
    return {
      dests: [
        {
          label: "Streaming",
          url: "https://streaming.peytersfamily.ch"
        },
      ]
    }
  },
  components: {
    Dropdown,
  }
}
</script>
